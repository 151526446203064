import React from 'react';

const ActivityPage = () => {
  return (
    <div id="activity-page">
      <section id="schedule" className="section">
        <div className="container">
          <h1 className="title">Activities</h1>
          <h2 className="subtitle">Activities for the <strong>2024</strong> season. More details coming soon, minor details might change without notice.</h2>
          <table className="table is-striped is-narrow is-hoverable is-fullwidth">
            <thead>
            <tr>
              <th>Date</th>
              <th width="20%">Activity</th>
              <th></th>
            </tr>
            </thead>
            <tbody>
            {/*April*/}
            {/*            <tr>
              <td colSpan="3" className="has-background-success"><strong className="has-text-white">April</strong></td>
            </tr>
            <tr>
              <td>28<sup>th</sup></td>
              <td colSpan="2">Red Rock Ponds RV Resort Opens For The Season</td>
            </tr>*/}
            {/*May*/}
            <tr>
              <td colSpan="3" className="has-background-success"><strong className="has-text-white">May</strong></td>
            </tr>
            <tr className="separator">
              <td colSpan="3"><strong>Opening Weekend</strong></td>
            </tr>
            <tr className="separator">
              <td>3<sup>rd</sup></td>
              <td colSpan="2">Red Rock Ponds RV Resort Opens For The Season</td>
            </tr>
            <tr>
              <td>4<sup>th</sup></td>
              <td className="has-text-right">8:00PM</td>
              <td>Bonfire - Inaugural campfire of the summer, join us up front.</td>
            </tr>
            <tr className="separator">
              <td colSpan="3"><strong>Mother&apos;s Day Weekend</strong></td>
            </tr>
            <tr className="separator">
              <td>10<sup>th</sup></td>
              <td className="has-text-right">5:00PM - 7:00PM</td>
              <td>Food Truck - <a href="https://melttruckroc.com/menu">The Melt Truck</a></td>
            </tr>
            <tr>
              <td rowSpan="3">11<sup>th</sup></td>
              <td className="has-text-right">10:00AM - 11:00AM</td>
              <td>Crafting - Pressed Flowers in a &quot;Jar&quot; Art ($2)</td>
            </tr>
            <tr>
              <td className="has-text-right">3:00PM - 4:00PM</td>
              <td>Mom&apos;s Party - Sip, Craft, Glow: Wine & DIY Lamps for Fabulous Moms</td>
            </tr>
            <tr>
              <td className="has-text-right">7:00PM - 8:00PM</td>
              <td>BINGO - All money returned prizes, win cool Red Rock merchandise!</td>
            </tr>
            <tr>
              <td>12<sup>th</sup></td>
              <td className="has-text-right">9:00AM - 10:00AM</td>
              <td>All you can eat breakfast w/ blueberry pancakes with all the toppings! Coffee and
                juice available. $4 per person. <br/>Mom eats free! Kids under 2 eat free.
              </td>
            </tr>
            <tr className="separator">
              <td>16<sup>th</sup></td>
              <td className="has-text-right">6:00PM - 7:00PM</td>
              <td>Happy Hour - Catch up, meet new people! Bring a appetizer, refreshment provided.</td>
            </tr>
            <tr className="separator">
              <td>18<sup>th</sup></td>
              <td className="has-text-right">7:00PM</td>
              <td>Poker night - All money returned Texas hold &apos;em, limited spots, signups required</td>
            </tr>
            <tr className="separator">
              <td colSpan="3"><strong>Memorial Day Weekend</strong></td>
            </tr>
            <tr className="separator">
              <td rowSpan="2">25<sup>th</sup></td>
              <td className="has-text-right">12:00PM - 1:00PM</td>
              <td>Beachside Cookout - Free burgers, hot dogs. Join us for a picnic at the beach.</td>
            </tr>
            <tr>
              <td className="has-text-right">7:00PM - 9:00PM</td>
              <td>Tasting - <a href="https://www.facebook.com/LakeOntarioVineyards/">Mayer&apos;s Lake Ontario
                Winery</a></td>
            </tr>
            <tr>
              <td rowSpan="2">26<sup>th</sup></td>
              <td className="has-text-right">7:00PM - 8:00PM</td>
              <td>BINGO - All money returned prizes, win cool Red Rock merchandise!</td>
            </tr>
            <tr>
              <td className="has-text-right">9:00PM - 11:00PM</td>
              <td>Movie night under the stars at the quad!</td>
            </tr>
            {/*June*/}
            <tr>
              <td colSpan="3" className="has-background-success"><strong className="has-text-white">June</strong></td>
            </tr>
            <tr className="separator">
              <td>1<sup>st</sup></td>
              <td className="has-text-right">7:00PM</td>
              <td>Trivia Night - Decades Delight Trivia Night; Test your knowledge across the decades with questions
                spanning from the &apos;60s to the 2000s.
              </td>
            </tr>
            <tr>
              <td>2<sup>nd</sup></td>
              <td className="has-text-right">9:00AM - 10:00AM</td>
              <td>Breakfast Club</td>
            </tr>
            <tr className="separator">
              <td>7<sup>th</sup></td>
              <td className="has-text-right">5:00PM - 7:00PM</td>
              <td>Food Truck - <a href="https://www.facebook.com/RedOsierFoodService/">Red Osier Food Truck</a></td>
            </tr>
            <tr>
              <td>8<sup>th</sup></td>
              <td className="has-text-right">3:00PM - 5:00PM</td>
              <td>Golf Cart Rodeo</td>
            </tr>
            <tr className="separator">
              <td colSpan="3"><strong>Father&apos;s Day Weekend</strong></td>
            </tr>
            <tr className="separator">
              <td rowSpan="4">15<sup>th</sup></td>
              <td className="has-text-right">10:00AM - 12:00PM</td>
              <td>Crafting - Dad & Bricks: A Lego-Building Bonanza for Father-Child Duos (Dad&apos;s (or a parent)
                required, $5+)
              </td>
            </tr>
            <tr>
              <td className="has-text-right">10:30AM - 11:30AM</td>
              <td>Yoga with Mandy! All skill levels welcome.</td>
            </tr>
            <tr>
              <td className="has-text-right">3:00PM - 4:00PM</td>
              <td>Yardlympics (dad&apos;s party): Games Galore for Glorious Glory and Prizes</td>
            </tr>
            <tr>
              <td className="has-text-right">7:00PM - 8:00PM</td>
              <td>BINGO - All money returned prizes, win cool Red Rock merchandise!</td>
            </tr>
            <tr>
              <td>16<sup>th</sup></td>
              <td className="has-text-right">9:00AM - 10:00AM</td>
              <td>All you can eat breakfast w/ blueberry pancakes with all the toppings! Coffee and
                juice available. $4 per person. Fathers eat free!
              </td>
            </tr>
            <tr>
              <td rowSpan="2">22<sup>th</sup></td>
              <td className="has-text-right">10:30AM - 11:30AM</td>
              <td>Yoga with Mandy! All skill levels welcome.</td>
            </tr>
            <tr className="separator">
              <td className="has-text-right">7:00PM</td>
              <td>Campfire Crawl - Like a pub crawl, visit designated bonfires around the campground and try the unique
                drinks each hosts has to offer.
              </td>
            </tr>
            <tr className="separator">
              <td rowSpan="2">29<sup>th</sup></td>
              <td className="has-text-right">6:00PM - 7:00PM</td>
              <td>Happy Hour - Catch up, meet new people! Bring a appetizer, refreshment provided.</td>
            </tr>
            <tr>
              <td className="has-text-right">7:00PM - 10:00PM</td>
              <td><a href="https://www.facebook.com/p/McHenry-and-Baz-100063616298954/">McHenry and Baz</a> - Great
                music from the
                60&apos;s, 70&apos;s 80&apos;s and 90&apos;s.
              </td>
            </tr>
            {/*July*/}
            <tr>
              <td colSpan="3" className="has-background-success"><strong className="has-text-white">July</strong></td>
            </tr>
            <tr>
              <td colSpan="3"><strong>4th of July Weekend</strong></td>
            </tr>
            <tr className="separator">
              <td rowSpan="4">5<sup>th</sup></td>
              <td className="has-text-right">10:00AM - 6:00PM</td>
              <td>Giant water slide Friday and Saturday.</td>
            </tr>
            <tr>
              <td className="has-text-right">3:00PM</td>
              <td>Book Club (at the beach) - &quot;The Women&quot; by Kristin Hannah. Headed by Michelle Whitmarsh on
                site 16.
              </td>
            </tr>
            <tr>
              <td className="has-text-right">4:00PM - 6:00PM</td>
              <td><a href="https://northridgedistillery.com/">North Ridge Distillery</a> - Tasting.</td>
            </tr>
            <tr>
              <td className="has-text-right">7:00PM - 8:00PM</td>
              <td>BINGO - All money returned prizes, win cool Red Rock merchandise!</td>
            </tr>
            <tr>
              <td rowSpan="4">6<sup>th</sup></td>
              <td className="has-text-right">10:30AM - 11:30AM</td>
              <td>Yoga with Mandy! All skill levels welcome.</td>
            </tr>
            <tr>
              <td className="has-text-right">2:00PM - 3:00PM</td>
              <td>4th of July Parade - Join us for a star-spangled salute at the 4th of July Parade! Honoring our Brave
                First Responders 🚑 🚒 🚓 This year, we&apos;re saluting our first responders in a dazzling display of
                community spirit. Decorate your golf carts in patriotic flair and roll down the streets with pride.
              </td>
            </tr>
            <tr>
              <td className="has-text-right">After Parade</td>
              <td>Ice Cream Social - Delicious Perry&apos;s ice cream. Plenty of flavors to choose from.</td>
            </tr>
            <tr>
              <td className="has-text-right">7:00PM - 9:30PM</td>
              <td><a href="https://www.facebook.com/p/The-Blackjack-Band-100064044843285/">Black Jack</a> - Live music.
              </td>
            </tr>
            <tr className="separator">
              <td colSpan="3"><strong>Superhero & Princess Weekend</strong></td>
            </tr>
            <tr className="separator">
              <td>12<sup>th</sup></td>
              <td className="has-text-right">5:00PM - 7:00PM</td>
              <td>Food Truck - <a href="https://www.facebook.com/curbsidequesadilla/">Curbside Quesadillas</a></td>
            </tr>
            <tr>
              <td rowSpan="4">13<sup>th</sup></td>
              <td className="has-text-right">10:30AM - 11:30AM</td>
              <td>Yoga with Mandy! All skill levels welcome.</td>
            </tr>
            <tr>
              <td className="has-text-right">2:00PM - 3:00PM</td>
              <td>Costume Contest - Heroes vs. Royalty: Epic Costume Gala for the Ages!</td>
            </tr>
            <tr>
              <td className="has-text-right">3:00PM - 4:00PM</td>
              <td>Special Visitor - A Magical Meet-and-Greet (photo ops)</td>
            </tr>
            <tr>
              <td className="has-text-right">7:30PM - 9:30PM</td>
              <td>Movie night under the stars at the quad!</td>
            </tr>
            <tr>
              <td>14<sup>th</sup></td>
              <td className="has-text-right">9:00AM - 10:00AM</td>
              <td>Breakfast Club - Breakfast Pizza</td>
            </tr>
            <tr className="separator">
              <td rowSpan="5">20<sup>th</sup></td>
              <td className="has-text-right">10:30AM - 11:30AM</td>
              <td>Yoga with Mandy! All skill levels welcome.</td>
            </tr>
            <tr>
              <td className="has-text-right">11:00AM - 12:00PM</td>
              <td>Blood Pressure Clinic - Heart condition prevention and proactive management.</td>
            </tr>
            <tr>
              <td rowSpan="2" className="has-text-right">4:00PM - 6:00PM</td>
              <td>Beach Party - Live DJ! Giant floats! Free toys and water blasters! Slushies for kids and drinks for
                adults. Enjoy the hot sun, warm sand and refreshing water.
              </td>
            </tr>
            <tr>
              <td>DJ by <a href="">DJ McKee</a></td>
            </tr>
            <tr>
              <td className="has-text-right">6:00PM - 7:00PM</td>
              <td>Chicken BBQ Dinner
                - Prepared by <a href="https://www.mjfd.org/">Murray Joint Fire District</a>. Fill up on
                delicious, mouth watering BBQ chicken! Three sides included. Ticket purchased in advance, remainder
                tickets can be purchased in person.
              </td>
            </tr>
            <tr className="separator">
              <td rowSpan="3">27<sup>th</sup></td>
              <td className="has-text-right">10:30AM - 11:30AM</td>
              <td>Yoga with Mandy! All skill levels welcome.</td>
            </tr>
            <tr>
              <td className="has-text-right">12:00PM - 3:00PM</td>
              <td>FHM/Holley Fire Department Fund Raiser Carnival</td>
            </tr>
            <tr>
              <td className="has-text-right">8:00PM - 10:00PM</td>
              <td><a href="https://www.youtube.com/watch?v=f80-6gTL5fk&feature=shared">Blew Shoes</a> - Live music by the beach.</td>
            </tr>
            {/*August*/}
            <tr>
              <td colSpan="3" className="has-background-success"><strong className="has-text-white">August</strong></td>
            </tr>
            <tr className="separator">
              <td rowSpan="4">3<sup>rd</sup></td>
              <td className="has-text-right">10:30AM - 11:30AM</td>
              <td>Yoga with Mandy! All skill levels welcome.</td>
            </tr>
            <tr>
              <td className="has-text-right">1:00PM</td>
              <td>Paint & Sip with Theresa, limited spots, sign up in office.</td>
            </tr>
            <tr>
              <td className="has-text-right">5:30PM - 6:30PM</td>
              <td>Happy Hour - Catch up, meet new people! Bring a appetizer, refreshment provided.</td>
            </tr>
            <tr>
              <td className="has-text-right">7:00PM - 8:00PM</td>
              <td>BINGO - All money returned prizes, win cool Red Rock merchandise!</td>
            </tr>
            <tr className="separator">
              <td>8<sup>th</sup></td>
              <td className="has-text-right">3:00PM</td>
              <td>Book Club (at the beach) - &quot;The Island&quot; by Elin Hilderbrand. Headed by Michelle Whitmarsh on
                site 16.
              </td>
            </tr>
            <tr className="separator">
              <td rowSpan="2">9<sup>th</sup></td>
              <td className="has-text-right">10AM</td>
              <td>Red Rock Golf Tournament (at Brown Road Golf Club) - $20 for 18 holes golf, cart, lunch and beer
                included! (Sign up with big Mike on site 237 or Les on 238, deadline to sign up: July 27th.)
              </td>
            </tr>
            <tr>
              <td className="has-text-right">5:00PM - 7:00PM</td>
              <td>Food Truck - <a href="https://www.facebook.com/Dubbyswoodfired/">Dubbys Wood Fired Pizza</a></td>
            </tr>
            <tr>
              <td rowSpan="2">10<sup>th</sup></td>
              <td className="has-text-right">10:00AM - 12:00PM</td>
              <td>Indigo Splash: Design, Dye, and make dye-tastic shirts</td>
            </tr>
            <tr>
              <td className="has-text-right">10:30AM - 11:30AM</td>
              <td>Yoga with Mandy! All skill levels welcome.</td>
            </tr>
            <tr>
              <td>11<sup>th</sup></td>
              <td className="has-text-right">9:00AM - 10:00AM</td>
              <td>Breakfast Club - TBA</td>
            </tr>
            <tr className="separator">
              <td rowSpan="6">17<sup>th</sup></td>
              <td className="has-text-right">10:30AM - 11:30AM</td>
              <td>Yoga with Mandy! All skill levels welcome.</td>
            </tr>
            <tr>
              <td className="has-text-right">11:00AM - 12:00PM</td>
              <td>Blood Pressure Clinic - Heart condition prevention and proactive management.</td>
            </tr>
            <tr>
              <td className="has-text-right">6:00PM - 7:00PM</td>
              <td>Pig Roast - {/*Prepared by Wally & Co.*/} Tender, juicy and savory prime BBQ pulled pork smoked all
                day! Served with 3 sides; Baked beans, tri-color pasta and mac salad. Tickets purchased in advance,
                remainder tickets can be purchased in person.
              </td>
            </tr>
            <tr>
              <td rowSpan="3" className="has-text-right">7:00PM - 9:30PM</td>
              <td><a href="https://www.facebook.com/p/The-Who-Dats-100063582307146/">The Who Dats</a> - Live Music</td>
            </tr>
            <tr>
              <td>Tiki Island Beach Bonfire - Join us for a magical night under the stars. Cozy up in our annual Tiki
                island beach bonfire in an intimate setting. Dance to the timeless classics and indulge a yummy island
                drink, or two.
              </td>
            </tr>
            <tr>
              <td>Bar Tended by <a href="">TBA</a></td>
            </tr>
            <tr className="separator">
              <td>22<sup>nd</sup></td>
              <td className="has-text-right">6:00PM - 7:00PM</td>
              <td>Happy Hour - Catch up, meet new people! Bring a appetizer, refreshment provided.</td>
            </tr>
            <tr>
              <td rowSpan="2">24<sup>th</sup></td>
              <td className="has-text-right">10:30AM - 11:30AM</td>
              <td>Yoga with Mandy! All skill levels welcome.</td>
            </tr>
            <tr>
              <td className="has-text-right">6:00PM</td>
              <td>Poker night - All money returned Texas hold &quot;em, limited spots, signups required</td>
            </tr>
            <tr className="separator">
              <td colSpan="3"><strong>Labor Day Weekend / Kids Weekend</strong></td>
            </tr>
            <tr className="separator">
              <td>30<sup>th</sup></td>
              <td className="has-text-right">5:00PM - 7:00PM</td>
              <td>Food Truck - <a href="https://www.facebook.com/GreenliefsOnTheGo/">Greenlief&apos;s on the Go</a></td>
            </tr>
            <tr>
              <td rowSpan="3">31<sup>st</sup></td>
              <td className="has-text-right">10:30AM - 11:30AM</td>
              <td>Yoga with Mandy! All skill levels welcome.</td>
            </tr>
            <tr>
              <td className="has-text-right">2:00PM - 3:00PM</td>
              <td>Red Rock 500 - Matchbox Cars Track Racing and derby. Bring your own car.</td>
            </tr>
            <tr>
              <td className="has-text-right">After Race</td>
              <td>Super soaker war! - Water gun available for $10.</td>
            </tr>
            {/*September*/}
            <tr>
              <td colSpan="3" className="has-background-success"><strong className="has-text-white">September</strong>
              </td>
            </tr>
            <tr>
              <td rowSpan="2">1<sup>st</sup></td>
              <td className="has-text-right">6:00PM - 7:00PM</td>
              <td>Ice Cream Social - Delicious Perry&apos;s ice cream. Plenty of flavors to choose from.</td>
            </tr>
            <tr>
              <td className="has-text-right">9:00PM - 11:00PM</td>
              <td>Movie night under the stars at the quad!</td>
            </tr>
            <tr className="separator">
              <td rowSpan="2">7<sup>th</sup></td>
              <td className="has-text-right">10:30AM - 11:30AM</td>
              <td>Yoga with Mandy! All skill levels welcome.</td>
            </tr>
            <tr>
              <td className="has-text-right">2:00pM - 4:00PM</td>
              <td>Fishing Derby</td>
            </tr>
            <tr>
              <td>8<sup>th</sup></td>
              <td className="has-text-right">9:00AM - 10:00AM</td>
              <td>Breakfast Club - Breakfast Sandwich</td>
            </tr>
            <tr className="separator">
              <td rowSpan="3">14<sup>th</sup></td>
              <td className="has-text-right">10:30AM - 11:30AM</td>
              <td>Yoga with Mandy! All skill levels welcome.</td>
            </tr>
            <tr>
              <td className="has-text-right">2:00PM - 4:00PM</td>
              <td>Cornhole Tournament - Win a custom cornhole board and bragging rights!</td>
            </tr>
            <tr>
              <td className="has-text-right">7:00PM - 8:00PM</td>
              <td>BINGO - All money returned prizes, win cool Red Rock merchandise!</td>
            </tr>
            <tr className="separator">
              <td>19<sup>th</sup></td>
              <td className="has-text-right">6:00PM - 7:00PM</td>
              <td>Happy Hour - Catch up, meet new people! Bring a appetizer, refreshment provided.</td>
            </tr>
            <tr className="separator">
              <td rowSpan="4">21<sup>st</sup></td>
              <td className="has-text-right">10:30AM - 11:30AM</td>
              <td>Yoga with Mandy! All skill levels welcome.</td>
            </tr>
            <tr>
              <td className="has-text-right">11:00AM - 12:00PM</td>
              <td>Blood Pressure Clinic - Heart condition prevention and proactive management.</td>
            </tr>
            <tr>
              <td rowSpan="2" className="has-text-right">7:00PM - 8:00PM</td>
              <td>Dance - Hollywood Gala; Strut, sway, and impersonate your favorite stars under the glittering lights.
                It&apos;s a night of glam, groove, and red carpet-worthy moves. Who will steal the show? Join us and
                dance like Hollywood&apos;s finest!
              </td>
            </tr>
            <tr>
              <td>DJ by <a href="">DJ McKee</a></td>
            </tr>
            <tr className="separator">
              <td rowSpan="2">28<sup>th</sup></td>
              <td className="has-text-right">10:30AM - 11:30AM</td>
              <td>Yoga with Mandy! All skill levels welcome.</td>
            </tr>
            <tr>
              <td className="has-text-right">5:00PM - 6:00PM</td>
              <td>Pizza & Wine Party - Celebrate another fun year with us, just bring a bottle of wine. Pizza and pops
                on us!
              </td>
            </tr>
            {/*October*/}
            <tr>
              <td colSpan="3" className="has-background-success"><strong className="has-text-white">October</strong>
              </td>
            </tr>
            <tr className="separator">
              <td rowSpan="2">5<sup>th</sup></td>
              <td className="has-text-right">10:00AM - 11:00AM</td>
              <td>Crafting - Pumpkin Carving</td>
            </tr>
            <tr>
              <td className="has-text-right">2:00PM - 4:00PM</td>
              <td>Fall Fair - Hayrides, Farmers&apos; Market, Craft Fair, Apple Bobbing (TBA)</td>
            </tr>
            <tr>
              <td>6<sup>th</sup></td>
              <td className="has-text-right">9:00AM - 10:00AM</td>
              <td>Breakfast Club</td>
            </tr>
            <tr className="separator">
              <td colSpan="3"><strong>Red Rock Halloween / Columbus Day Weekend</strong></td>
            </tr>
            <tr className="separator">
              <td rowSpan="5">12<sup>th</sup></td>
              <td className="has-text-right">3:00PM</td>
              <td>Trick or Treating - Wear your customs and meet at the main building.<br/><strong>NO vehicles past the
                main
                gate after 2:45PM</strong>
              </td>
            </tr>
            <tr>
              <td rowSpan="4" className="has-text-right">5:00PM</td>
              <td>Light Tour - Take a wagon ride and check out the awesome decoration through out the park.</td>
            </tr>
            <tr>
              <td>Haunted Lake Trails - Watch your back, watch your sides for what&apos;s lurking in the woods and in
                the pond!
              </td>
            </tr>
            <tr>
              <td>Hot Cider and Donuts - Get a cup of hot cider and donuts in between the tour.</td>
            </tr>
            <tr>
              <td><strong>Vote for the best decorated site! Prize available for 1st, 2nd and 3rd place.</strong></td>
            </tr>
            <tr>
              <td>13<sup>th</sup></td>
              <td className="has-text-right">7:00PM - 8:00PM</td>
              <td>BINGO - All money returned prizes, win cool Red Rock merchandise!</td>
            </tr>
            <tr className="separator">
              <td>19<sup>th</sup></td>
              <td className="has-text-right">11:00AM - 12:00PM</td>
              <td>Blood Pressure Clinic - Heart condition prevention and proactive management.</td>
            </tr>
            </tbody>
            <tfoot>
            <tr>
              <th colSpan="4">Season concludes end of day Oct 20th.</th>
            </tr>
            </tfoot>
          </table>
        </div>
      </section>
    </div>
  );
};

export default ActivityPage;
